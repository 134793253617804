<!-- 导航区域用户信息 -->
<template>
  <div class="userInfo">
    <div v-if="!getToken()" style="margin-top: 32px">
      <el-button type="primary" @click="navTo(loginPath)">登录</el-button>
      <el-button type="primary" @click="navTo('/register')">注册</el-button>
    </div>

    <div v-else class="content">
      <el-popover placement="bottom" trigger="hover">
        <template #reference>
          <div class="user-box">
            <!-- <div class="user-img">
              <img v-if="catchImage" :src="catchImage" />
              <img v-else src="/empty-user.png" />
            </div> -->
            <span class="line-ellipsis welcome">{{ userInfo?.UserName }}</span>
            <span class="icon">
              <el-icon :size="12"><ArrowDown /></el-icon>
            </span>
          </div>
        </template>
        <ul class="userInfo-item">
          <li @click="passwordVisible = true">
            <el-icon><Setting /></el-icon>
            <span>修改密码</span>
          </li>
          <li @click="logout">
            <el-icon><SwitchButton /></el-icon>
            <span>退出登录</span>
          </li>
        </ul>
      </el-popover>
    </div>
  </div>

  <!-- 修改密码弹窗 -->
  <ModifyPassword :visible="passwordVisible" @close="passwordVisible = false" />
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from "vue";
import { SwitchButton, ArrowDown, Setting } from "@element-plus/icons-vue";
import { getToken, dispatchLogout } from "@/utils/token";
import router from "@/router";
import { handle_succ, handle_err } from "@/utils/function";
import { userBaseInfo } from "@/api/user";
import ModifyPassword from "./ModifyPassword.vue";

defineProps({
  path: String,
});

const emit = defineEmits(["changeUserInfo"]);

interface UserInfo {
  UserName: string;
}
const userInfo = ref<UserInfo>();
const loginPath = "/login";

// 修改密码弹窗是否显示
const passwordVisible = ref(false);

const navTo = (path: string) => {
  // 优选选择地址栏中传入的数据，因为异步请求会延时，造成页面闪烁
  router.push({
    path,
  });
};

const logout = () => {
  dispatchLogout();
  // removeUserInfo();
  handle_succ("退出成功");
  router.push("/login");
};

// 获取用户详细信息
const getUserBaseInfo = async () => {
  userBaseInfo()
    .then(({ Data }) => {
      userInfo.value = Data;
    })
    .catch((err) => {
      console.log(err);
      handle_err(err);
    });
};

onMounted(() => {
  if (getToken()) {
    getUserBaseInfo();
  }
});

watch(
  () => userInfo.value,
  (newValue) => {
    emit("changeUserInfo", newValue);
  }
);
</script>
<style lang="scss" scoped>
.userInfo {
  height: 62px;
  width: 250px;
  background: rgba(255, 255, 255, 0.39);
  display: flex;
  justify-content: flex-end;
}
.userInfo-item {
  width: 80px;
  display: block;
  li {
    line-height: 30px;
    display: flex;
    align-items: center;
    span {
      margin-left: 5px;
    }
    &:hover {
      color: #409eff;
      cursor: pointer;
    }
  }
}
.icon {
  margin-left: 16px;
}
.user-box {
  display: flex;
  align-items: center;
  height: 100px;
  height: 20px;
  padding-top: 40px;
}
.welcome {
  font-size: 14px;
  color: #666;
  display: inline-block;
  max-width: 144px;
  line-height: 100px;
}
.user-img {
  width: 38px;
  height: 38px;
  border-radius: 50%;
  overflow: hidden;
  margin-right: 16px;
  img {
    width: 100%;
    height: 100%;
  }
}
.content {
  display: flex;
}
</style>
