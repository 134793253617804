<!-- YourComponent.vue -->
<template>
  <!-- 溯源平台子菜单 -->
  <el-menu
    :default-active="activeMenu"
    :default-openeds="openeds"
    @select="handleMenuSelect"
  >
    <el-sub-menu index="maintain">
      <template #title>数据维护</template>
      <el-menu-item index="deviceManage">设备管理</el-menu-item>
      <el-menu-item index="pointManage">点位管理</el-menu-item>
      <el-menu-item index="drainageUnit">排水户管理</el-menu-item>
      <el-menu-item index="water">排水分区管理</el-menu-item>
      <el-menu-item index="pipe">管网管理</el-menu-item>
    </el-sub-menu>
    <el-sub-menu index="visualization">
      <template #title>数据可视化</template>
      <el-menu-item index="oneMap">一张图</el-menu-item>
      <el-sub-menu index="trend">
        <template #title>指标趋势</template>
        <el-menu-item index="pointMetrics">点位维度</el-menu-item>
        <el-menu-item index="deviceMetrics">设备维度</el-menu-item>
      </el-sub-menu>
      <el-menu-item index="trace">溯源结果</el-menu-item>
      <!-- <el-menu-item index="deviceInfo">设备信息</el-menu-item> -->
    </el-sub-menu>
    <el-sub-menu index="device">
      <template #title>设备维护</template>
      <el-menu-item index="warningSettings">报警设置</el-menu-item>
      <el-menu-item index="warningHistory">报警列表</el-menu-item>
    </el-sub-menu>
  </el-menu>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
const router = useRouter();
const route = useRoute();

const activeMenu = ref();

// 默认展开的菜单
const openeds = ["maintain", "visualization", "trend", "device"];

const pathMap = {
  deviceManage: "/traceability/maintain/deviceManage",
  pointManage: "/traceability/maintain/point",
  drainageUnit: "/traceability/maintain/drainageUnit",
  water: "/traceability/maintain/water",
  pipe: "/traceability/maintain/pipe",
  oneMap: "/traceability/visualization/map",
  pointMetrics: "/traceability/visualization/trend/point",
  deviceMetrics: "/traceability/visualization/trend/device",
  trace: "/traceability/visualization/results",
  deviceInfo: "/traceability/visualization/deviceInfo",
  warningSettings: "/traceability/device/warningSettings",
  warningHistory: "/traceability/device/warningHistory",
};

const initActiveMenu = () => {
  setTimeout(() => {
    Object.entries(pathMap).forEach(([key, value]) => {
      if (route.path.startsWith(value)) {
        activeMenu.value = key;
      }
    });
    if (!activeMenu.value) {
      initActiveMenu();
    }
  }, 100);
};

const handleMenuSelect = (index: string) => {
  Object.entries(pathMap).forEach(([key, value]) => {
    if (key === index) {
      activeMenu.value = index;
      // 执行菜单选择后的逻辑
      router.push(value);
    }
  });
};

onMounted(() => {
  initActiveMenu();
});

watch(
  () => route.path,
  () => {
    initActiveMenu();
  }
);
</script>

<style scoped lang="scss">
.el-menu {
  height: 100%;
}
</style>
