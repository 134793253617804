<template>
  <!-- 后台管理子菜单 -->
  <el-menu :default-active="activeMenu" @select="handleMenuSelect">
    <el-menu-item index="userManage">用户列表</el-menu-item>
    <el-menu-item index="tagManage">标签管理</el-menu-item>
  </el-menu>
</template>

<script setup lang="ts">
import { ref, onMounted, watch } from "vue";
import { useRoute, useRouter } from "vue-router";
const router = useRouter();
const route = useRoute();

const activeMenu = ref();

const pathMap = {
  userManage: "/admin/userManage",
  tagManage: "/admin/tagManage",
};

const initActiveMenu = () => {
  setTimeout(() => {
    Object.entries(pathMap).forEach(([key, value]) => {
      if (route.path.startsWith(value)) {
        activeMenu.value = key;
      }
    });
    if (!activeMenu.value) {
      initActiveMenu();
    }
  }, 100);
};

const handleMenuSelect = (index: string) => {
  Object.entries(pathMap).forEach(([key, value]) => {
    if (key === index) {
      activeMenu.value = index;
      // 执行菜单选择后的逻辑
      router.push(value);
    }
  });
};

onMounted(() => {});

watch(
  () => route.path,
  () => {
    initActiveMenu();
  },
  {
    immediate: true,
  }
);
</script>

<style scoped lang="scss">
.el-menu {
  height: 100%;
}
</style>
