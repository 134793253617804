import { RouteRecordRaw } from "vue-router";

const suyuanRoutes: RouteRecordRaw[] = [
  // 溯源平台 - 设备管理
  {
    path: "/traceability/maintain/deviceManage",
    component: () =>
      import("../views/traceability/maintain/deviceManage/index.vue"),
  },
];

export default suyuanRoutes;
