import axios from "axios";
import router from "@/router";
import { getToken, dispatchLogout } from "@/utils/token";

import type {
  AxiosError,
  AxiosInstance,
  AxiosResponse,
  InternalAxiosRequestConfig,
} from "axios";
import { toLogin } from "@/utils/function";

const service: AxiosInstance = axios.create({
  baseURL: "/v2",
  timeout: 10 * 1000, // 请求超时时间
  headers: { "Content-Type": "application/json;charset=UTF-8" },
});

service.interceptors.request.use((config: InternalAxiosRequestConfig) => {
  const Token = getToken();
  config!.headers!.Authorization = Token;

  return config;
});

// 定义响应拦截器 -->token值无效时,清空token,并强制跳转登录页
service.interceptors.response.use(
  (response: AxiosResponse) => {
    const data = response.data;
    if (data.code == null) {
      // 流文件直接返回数据
      return data;
    }

    if (data.Code === 0) {
      return data;
    } else {
      console.log("global code error-->", data.Code);

      // // @ts-ignore
      // ElMessage.error(data.Message);
      return Promise.reject(data);
    }
  },
  (err) => {
    const { Code, Message } = err.response?.data;
    console.log("global error-->", err, Message);
    if (Code === 221 || Code === 222 || Code === 223 || Code === 214) {
      /**
        221:无效的Token
        222:Token已过期
        223:Token为空
        214:用户被锁定
      */
      dispatchLogout();
      // removeToken();
      //   removeUserInfo();
      toLogin(router);
    }
    // @ts-ignore
    // ElMessage.error(Message);
    return Promise.reject(err.response?.data);
  }
);

const request = {
  get<T = any>(url: string, data?: any, config?: any): Promise<T> {
    return request.request("GET", url, { params: data }, config);
  },
  post<T = any>(url: string, data?: any, config?: any): Promise<T> {
    return request.request("POST", url, { data }, config);
  },
  patch<T = any>(url: string, data?: any, config?: any): Promise<T> {
    return request.request("PATCH", url, { data }, config);
  },
  put<T = any>(url: string, data?: any): Promise<T> {
    return request.request("PUT", url, { data });
  },
  delete<T = any>(url: string, data?: any): Promise<T> {
    return request.request("DELETE", url, { params: data });
  },
  request<T = any>(
    method = "GET",
    url: string,
    data?: any,
    config?: any
  ): Promise<T> {
    return new Promise((resolve, reject) => {
      const params = { method, url, ...data };

      if (config?.onUploadProgress) {
        params.onUploadProgress = config.onUploadProgress;
      }
      if (config?.headers) {
        params.headers = config.headers;
      }
      if (config?.timeout) {
        params.timeout = config.timeout;
      }
      if (config?.cancelToken) {
        params.cancelToken = config.cancelToken.token;
      }
      if (config?.responseType) {
        params.responseType = config.responseType;
      }

      service(params)
        .then((res) => {
          resolve(res as unknown as Promise<T>);
        })
        .catch((e: Error | AxiosError) => {
          reject(e);
        });
    });
  },
};

export default request;
