import { useUserStore } from "@/store/user";

export const getToken = () => {
  const user = useUserStore();
  return user.token || "";
};

export const dispatchLogout = () => {
  const user = useUserStore();
  user.logout();
};

// export const getUserInfo = () => {
//   let userInfo = {};
//   const userInfoStr = localStorage.getItem("UserInfo");
//   if (userInfoStr) {
//     userInfo = JSON.parse(userInfoStr);
//   }
//   return userInfo;
// };

// export const setUserInfo = (UserInfo: any) => {
//   return localStorage.setItem("UserInfo", JSON.stringify(UserInfo));
// };

// export const removeUserInfo = () => {
//   return localStorage.removeItem("UserInfo");
// };
