<!-- YourComponent.vue -->
<template>
  <!-- 监测平台子菜单 -->
  <Monitor v-if="route.path.startsWith('/monitor')" />

  <!-- 溯源平台子菜单 -->
  <SuyuanHeader v-if="route.path.startsWith('/traceability')" />

  <!-- 后台管理子菜单 -->
  <AdminHeader v-if="route.path.startsWith('/admin')" />
</template>

<script setup lang="ts">
import { useRoute } from "vue-router";
import SuyuanHeader from "./suyuan.vue";
import Monitor from "./monitor.vue";
import AdminHeader from "./admin.vue";

const route = useRoute();
</script>

<style scoped lang="scss">
.el-menu {
  height: 100%;
}
</style>
