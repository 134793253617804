<!-- 用户自行修改密码 -->
<template>
  <div>
    <el-dialog
      :model-value="props.visible"
      title="修改密码"
      width="30%"
      destroy-on-close
      @close="emits('close')"
    >
      <el-form class="form" ref="ruleFormRef" :model="formData" :rules="rules">
        <el-form-item prop="OldPassword">
          <div class="info-item">
            <span class="label">旧密码</span>
            <el-input v-model="formData.OldPassword" placeholder="旧密码" />
          </div>
        </el-form-item>
        <el-form-item prop="NewPassword">
          <div class="info-item">
            <span class="label">新密码</span>
            <el-input
              v-model="formData.NewPassword"
              placeholder="新密码"
              show-password
            />
          </div>
        </el-form-item>
        <el-form-item prop="ConfirmPassword">
          <div class="info-item">
            <span class="label">确认新密码</span>
            <el-input
              v-model="formData.ConfirmPassword"
              placeholder="确认密码"
              show-password
            />
          </div>
        </el-form-item>
      </el-form>
      <template #footer>
        <span class="dialog-footer">
          <el-button @click="emits('close')">取消</el-button>
          <el-button type="primary" @click="updatePassword(ruleFormRef)">
            确定
          </el-button>
        </span>
      </template>
    </el-dialog>
  </div>
</template>

<script setup lang="ts">
import { ref } from "vue";
import type { FormInstance, FormRules } from "element-plus";
import { handle_succ, handle_err, filterParams } from "@/utils/function";
import { modifyPassword } from "@/api/user";

const props = defineProps({
  visible: Boolean,
});
const emits = defineEmits(["close"]);

const ruleFormRef = ref<FormInstance>();

// 忘记密码需要提交的数据
interface Form {
  OldPassword: string;
  NewPassword: string;
  ConfirmPassword: string;
}
// 忘记密码表单数据
const formData = ref<Form>({
  OldPassword: "", // 旧密码
  NewPassword: "", // 新密码
  ConfirmPassword: "", // 确认新密码
});

// 表单验证规则
const rules = ref<FormRules<Form>>({
  OldPassword: [
    {
      required: true,
      trigger: "blur",
    },
  ],
  NewPassword: [
    { required: true, message: "新密码不能为空", trigger: ["change", "blur"] },
  ],
  ConfirmPassword: [
    {
      required: true,
      message: "确认密码不能为空",
      trigger: ["change", "blur"],
    },
  ],
});

// 更新密码
const updatePassword = async (formEl: FormInstance | undefined) => {
  if (!formEl) return;
  if (formData.value.NewPassword != formData.value.ConfirmPassword) {
    handle_err("新密码和确认密码输入不一致");
    return;
  }

  formEl.validate(async (valid, fields) => {
    if (valid) {
      modifyPassword(filterParams(formData.value))
        .then(() => {
          handle_succ("修改密码成功", () => {
            emits("close");
          });
        })
        .catch((err: any) => {
          console.log(err);
          handle_err(err);
        });
    } else {
      console.log("error submit!", fields);
    }
  });
};
</script>
<style lang="scss" scoped>
.info-item {
  display: flex;
  align-items: center;
  width: 100%;

  .label {
    font-size: 14px;
    color: #333;
    letter-spacing: 0;
    text-align: right;
    width: 70px;
    height: 40px;
    line-height: 40px;
    margin-right: 15px;
    font-weight: 200;
  }

  .code-label {
    letter-spacing: 4px;
  }
  .code-btn {
    height: 36px;
    width: 100px;
  }
  .el-input {
    flex: 1;
  }
}
:deep(.el-input__wrapper) {
  height: 40px;
}
</style>
