import request from "./request";

// 登录
export const login = (data?: any) => request.post("/users/login", data);

// 注册
export const register = (data?: any) => request.post("/users/user/add", data);

// 获取用户详细信息
export const userBaseInfo = (data?: any) =>
  request.get("/users/user/info", data);

// 获取用户详细列表
export const userList = (data?: any) => request.get("/users/user/list", data);

// 修改用户信息（密码/角色/状态）
export const modifyUser = (id: any, data?: any) =>
  request.patch(`/users/user/${id}/modify`, data);

// 发送验证码
export const verifySend = (data?: any) => request.post("/verify/send", data);

// 用户自行重置密码
export const resetPassword = (data?: any) =>
  request.patch(`/users/user/password/reset`, data);

// 用户自行修改密码
export const modifyPassword = (data?: any) =>
  request.patch(`/users/user/password`, data);

// 管理员强制修改用户密码
export const forcePassword = (data?: any) =>
  request.patch(`/admin/force_password`, data);

// 删除用户
export const deleteUser = (data?: any) =>
  request.delete(`/users/user/delete`, data);
